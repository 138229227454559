import './App.css';
import Home from './bwDotDev/home/Home';

function App() {
  return (
    <div className='app-container'>
      <div className='tri2'></div>
      <div className='tri4'></div>
      <div className='tri3'></div>
      <div className='circle'></div>
      {/* <div className='tri1'></div> */}
      <Home />

    </div>
  );
}

export default App;
